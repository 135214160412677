import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Bgthankyou from "../images/thankyou-bg.jpg"

const ThankYou = () => (
  <Layout>
    <SEO title="Thank You" description="Thank You" />
    <div
      className="page-headline"
      style={{ backgroundImage: `url(${Bgthankyou})` }}
    >
      <div className="container">
        <div className="section-heading text-center">
          <h1 className="font-weight-bold text-uppercase flair">
            <strong>Thank You</strong>
          </h1>
        </div>
      </div>
    </div>
    <div id="contact" className="thankyou-section padding-200">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h3>THANK YOU! WE WILL GET BACK TO YOU AS SOON AS POSSIBLE!</h3>
            <div className="mt-5">
              <Link
                className="btn-global hvr-bounce-to-right hvr-icon-buzz text-center"
                to="/"
              >
                Back TO HOME
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ThankYou
